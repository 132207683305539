import { jsx as _jsx } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';
export default function RHFAutocomplete({ name, label, helperText, ...other }) {
    const { control, setValue } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(Autocomplete, { ...field, filterOptions: (options, state) => options, onChange: (event, newValue) => setValue(name, newValue, { shouldValidate: true }), onInputChange: (event) => {
                event.preventDefault();
            }, renderInput: (params) => (_jsx(TextField, { label: label, error: !!error, helperText: error ? error?.message : helperText, ...params })), ...other })) }));
}
